/* eslint-disable no-param-reassign */

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import { localStoragePrefix } from "../scripts/utils";
import affairManage from "./modules/affairManage";
import user from "./modules/user";
import examReport from "./modules/examReport";
import scanErrorHandle from './modules/scanErrorHandle'
import createExam from "./modules/createExam";
import schoolConfig from "./modules/school-config";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    key: 'single_examination_vuex',
  })
  ],
  state: {
    // 在相应的handleProcess.vue中拿到班级数组，更改数据。
    // currentClass存储后四个组件共用
    getResultSuccess: 0, // 标记成绩报告结果是否获取成功，0是初始值，1是未获取成功，2是获取数据成功
    students: [],
    currentClass: {},
    // 每次修改分数后需要触发更新的值
    SmartResult: {},
    results: [],
    // 地址参数变化时拿到对应的请求参数，App.vue中
    examId: 0,
    subjectId: 0,
    gradeId: 0,
    // 储存进入到详情页面返回按钮功能的状态
    resultDetailShow: false,
    replyDetailShow: false,
    exceptionDetailShow: false,
    markDetailShow: false,
    // 不同试卷引用答题卡的Id存储
    sheetId: {},
    scoreLoadingShow: false,
    isJointExam: false,
    routeNameInVuex: "",
    lastInfoObj: {}, // 异常处理中心记录上一次操作的信息
    seachCondition: {}, // 异常处理中心搜索条件
    currentNowToolProcess: '', // 阅卷流程处于哪一个阶段
    previousBatchSetInfo: {}, // 用户批量设置的题块
    rollBackFirstSetting: false
  },
  mutations: {
    setGetResultSuccess(state, payload) {
      state.getResultSuccess = payload.getResultSuccess;
    },

    setStudents(state, payload) {
      state.students = payload.students;
    },
    setCurrentClass(state, payload) {
      state.currentClass = payload.currentClass;
    },

    setSmartResult(state, payload) {
      state.SmartResult = payload.SmartResult;
    },

    setExamId(state, id) {
      window.sessionStorage.setItem(`${localStoragePrefix}current_exam_id`, id);
      state.examId = id;
    },
    // 设置年级id 主要是针对小学、初中政治名字为道德与法治，高中为政治
    setGradeId(state, id) {
      state.gradeId = id
    },
    getExamId(state) {
      state.examId = window.sessionStorage.getItem(
        `${localStoragePrefix}current_exam_id`
      );
    },
    setRouteIds(state, { examId, subjectId, sheetId }) {
      state.examId = examId;
      state.subjectId = subjectId;
      state.sheetId = sheetId;
    },

    setResultDetailShow(state, payload) {
      state.resultDetailShow = payload.resultDetailShow;
    },
    setExceptionDetailShow(state, payload) {
      state.exceptionDetailShow = payload.exceptionDetailShow;
    },
    setMarkDetailShow(state, payload) {
      state.markDetailShow = payload.markDetailShow;
    },
    setReplyDetailShow(state, payload) {
      state.replyDetailShow = payload.replyDetailShow;
    },

    setSheetId(state, payload) {
      state.sheetId[payload.examId] = payload.sheetId;
    },

    setScoreLoadingShow(state, payload) {
      state.scoreLoadingShow = payload.scoreLoadingShow;
    },
    setIsJointExam(state, isJointExam) {
      state.isJointExam = isJointExam;
    },
    updateCurrentRouteName(state, data) {
      state.routeNameInVuex = data;
      sessionStorage.setItem(`${localStoragePrefix}current_route_name`, data);
    },
    getCurrentRouteNameFromLocal(state) {
      state.routeNameInVuex = sessionStorage.getItem(
        `${localStoragePrefix}current_route_name`
      );
    },
    updateLastInfoObj(state, data) {
      state.lastInfoObj[data.resultId] = data.blockId;
    },
    deleteItemInLastInfoObj(state, key) {
      if (key in state.lastInfoObj) {
        delete state.lastInfoObj[key];
      }
    },
    clearLastInfoObj(state) {
      state.lastInfoObj = {};
    },
    saveSearchCondition(state, data) {
      state.seachCondition = data;
    },
    updateProcessNowTool(state, data) {
      state.currentNowToolProcess = data
    },
    updatePreviousBatchSetInfo(state, data) {
      if (Object.keys(data).length === 0) {
        state.previousBatchSetInfo = {}
        return
      }
      // type是对应题目类型 1是单选 2是多选 13是填空
      const { examId, subjectId, batchSetInfo } = data
      const targetKey = `${examId}&${subjectId}`
      state.previousBatchSetInfo[targetKey] = batchSetInfo
    },
    // 从某一阶段回滚到答题卡设置，初始化到引用答题卡界面
    updateRollBackFirstSetting(state,boolean) {
      state.rollBackFirstSetting = boolean
    }
  },
  getters: {},
  actions: {
    getSmartResult({ state, commit }, payload) {
      // Vue.prototype.$serve
      //   .getSmartResultS({
      //     exam_id: state.examId,
      //     subject_id: state.subjectId,
      //     school_id: sessionStorage.getItem(
      //       `${localStoragePrefix}user_school_id`
      //     ),
      //     class_id: payload.currentClassId
      //   })
      //   .then(res => {
      //     commit("setSmartResult", {
      //       SmartResult: res.body
      //     });
      //     commit("setGetResultSuccess", {
      //       getResultSuccess: 2
      //     });
      //     commit("setScoreLoadingShow", {
      //       scoreLoadingShow: false
      //     });
      //   })
      //   .catch(() => {
      //     console.warn("无该场考试阅卷结果");
      //     commit("setGetResultSuccess", {
      //       getResultSuccess: 1
      //     });
      //     commit("setScoreLoadingShow", {
      //       scoreLoadingShow: false
      //     });
      //   });
    },

    clearIntervalId({ state }) {
      clearInterval(state.intervalId);
    }
  },
  modules: {
    affairManage,
    user,
    examReport,
    scanErrorHandle,
    createExam,
    schoolConfig,
  }
});
