import MsgComponent from './message.vue';

const Msg = {};

Msg.install = (Vue) => {
  const vue = Vue;
  const MsgClass = vue.extend(MsgComponent);
  const instance = new MsgClass();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);

  const MsgMain = {
    show_message(title, text, type, sameIdStudentInfos) {
      instance.title = title;
      instance.text = text;
      instance.visible = true;
      instance.detailShow = false;
      instance.type = type;
      instance.sameIdStudentInfos = sameIdStudentInfos
    },
  };

  vue.prototype.$msg = MsgMain;
};

export default Msg;
