import Vue from "vue";
import {
  analyzeSubjectMap,
  analyzeGradeMap,
  analyzeTypeMap,
  analyzeExamMode,
} from "@/scripts/analyzeMap";

const affairManage = {
  namespaced: true,
  state: {
    examId: 0,
    userRole: 0,
    examInfo: {},
    userPermission: {},
    classList: [],
    importFinished: false,
  },
  getters: {
    // 阅卷模式
    markModeName(state) {
      return analyzeExamMode[state.examInfo.mode];
    },
    grade(state) {
      const { grade_id } = state.examInfo;
      return { grade_id, grade_name: analyzeGradeMap[grade_id] ? analyzeGradeMap[grade_id].name : "" };
    },
    subjectList(state) {
      const { subject_ids = [], grade_id } = state.examInfo;
      return subject_ids.map((id) => ({
        subject_id: id,
        subject_name:
          +id === 7 && +grade_id <= 9
            ? "道德与法治"
            : +id === 7 && +grade_id >= 10
            ? "政治"
            : analyzeSubjectMap[id],
      }));
    },
    examDate(state) {
      return state.examInfo.start_date.substr(0, 10);
    },
    examSchools(state) {
      return state.examInfo.joint_schools || [];
    },
    permissionLevel(state) {
      return state.userPermission.permission.level;
    },
    permissionCityList(state) {
      if (!state.userPermission.permission) return [];
      const {
        permission: { city_list },
      } = state.userPermission;
      return city_list;
    },
    permissionCountyList(state) {
      if (!state.userPermission.permission) return [];
      const {
        permission: { county_list },
      } = state.userPermission;
      return county_list;
    },
    permissionSchoolList(state) {
      if (!state.userPermission.permission) return [];
      const {
        permission: { school_list },
      } = state.userPermission;
      return school_list;
    },
    permissionSubjectList(state) {
      if (!state.userPermission.permission) return [];
      const {
        permission: { subject_list },
      } = state.userPermission;
      return subject_list;
    },
    permissionGradeList(state) {
      if (!state.userPermission.permission) return [];
      const {
        permission: { grade_list },
      } = state.userPermission;
      return grade_list;
    },
  },
  mutations: {
    SET_USER_ROLE(state, payload) {
      state.userRole = payload.userRole;
    },
    SET_EXAM_ID(state, payload) {
      state.examId = payload.examId;
    },
    SET_EXAM_INFO(state, payload) {
      state.examInfo = payload.examInfo;
    },
    SET_USER_PERMISSION(state, payload) {
      state.userPermission = payload.userPermission;
    },
  },
  actions: {
    async GET_EXAM_INFO({ state, commit, dispatch }) {
      const { body } = await Vue.prototype.$serve.getSingleExamS(state.examId);

      // 获取这场考试包含的科目列表
      const { subject_ids, grade_id, exam_type } = body;
      body.subject_list = subject_ids.map((e) => {
        return {
          id: e,
          name:
            +e === 7 && +grade_id <= 9
              ? "道德与法治"
              : +e === 7 && +grade_id >= 10
              ? "政治"
              : analyzeSubjectMap[e],
        };
      });

      // 获取这场考试相同学段的年级列表
      const stage = analyzeGradeMap[grade_id].stage;
      const gradeList = [];
      for (let gradeId in analyzeGradeMap) {
        if (analyzeGradeMap[gradeId].stage === stage) {
          gradeList.push({ id: Number(gradeId), name: analyzeGradeMap[gradeId].name });
        }
      }
      body.grade_list = gradeList;

      body.exam_type_name = analyzeTypeMap[exam_type];

      // console.log(JSON.parse(JSON.stringify(body)));
      commit("SET_EXAM_INFO", { examInfo: body });
      dispatch("GET_CLASS_LIST");
    },

    async GET_USER_PERMISSION({ rootState, commit }) {
      const {
        user: { permission },
      } = rootState;
      commit("SET_USER_PERMISSION", {
        userPermission: permission,
      });
    },

    async GET_CLASS_LIST({ state }) {
      state.classList = [];
      const { class_ids } = state.examInfo;
      const { body } = await Vue.prototype.$serve.analyzeClassS(class_ids.toString());
      body.sort((a, b) =>
        +a.name.replace(/[^0-9]/gi, "") > +b.name.replace(/[^0-9]/gi, "") ? 1 : -1
      );
      state.classList = body;
    },

    async GET_IMPORT_STATUS({ state }) {
      state.importFinished = false;
      const {
        body: { data },
      } = await Vue.prototype.$serve.getImportStatus({ exam_id: state.examId });
      state.importFinished = data;
    },
  },
};

export default affairManage;
