const mainPageList = [
  // {
  //   name: '首页',
  //   path: '/MainPage/homePage',
  //   status: 1
  // },
  // {
  //   name: '教学监管',
  //   path: '/MainPage/teachingSupervision',
  //   status: 0,
  // },
  // {
  //   name: '考试报告',
  //   path: '/MainPage/testReport',
  //   children: [{
  //     name: '校级报告',
  //     path: '/MainPage/testReport/schoolReportList',
  //     status: 0
  //   },

  //   {
  //     name: '班级报告',
  //     path: '/MainPage/testReport/classReportList',
  //     status: 0
  //   },
  //   {
  //     name: '联考报告',
  //     path: '/MainPage/testReport/jointExam',
  //     status: 0
  //   }
  //   ],
  //   status: 0,
  // },
  {
    name: '考试阅卷',
    path: '/MainPage/markPaper',
    children: [
      {
        name: '考试管理',
        path: '/MainPage/exam-manage',
        status: 0,
      },
      {
        name: '阅卷任务',
        path: '/MainPage/markPage',
        status: 1,
        children: [
          {
            name: '我的任务',
            path: '/MainPage/markPage/unfinishList',
            status: 1,
          },
          // {
          //   name: '已结束',
          //   path: '/MainPage/markPage/finishList',
          //   status: 1,
          // },
          {
            name: '试卷仲裁',
            path: '/MainPage/markPage/arbitration-home/0',
            status: 1,
          },
        ],
      },
      {
        name: '答题卡管理',
        path: '/MainPage/markPaper/answerSheet',
        status: 0,
      },
      {
        name: '评卷监管',
        path: '/MainPage/markPaper/evaluationRegulationHome',
        status: 0,
      },
    ],
    status: 0,
  },
  {
    name: '异常处理中心',
    path: '/MainPage/error-home',
    children: [],
    status: 0,
  },
  // 作业系统独立
  // {
  //   name: '作业练习',
  //   path: '/MainPage/homework',
  //   children: [
  //     {
  //       name: '作业管理',
  //       path: '/MainPage/homework/homeworkReview',
  //       status: 0
  //     },
  //     {
  //       name: '批改任务',
  //       path: '/MainPage/homework/workMarkPage',
  //       status: 1,
  //       children: [{
  //         name: '我的任务',
  //         path: '/MainPage/homework/workMarkPage/workUnfinishList',
  //         status: 1
  //       },
  //       {
  //         name: '已结束',
  //         path: '/MainPage/homework/workMarkPage/workFinishList',
  //         status: 1
  //       },
  //       ]
  //     },
  //   ],
  //   status: 0
  // },

  // {
  //   name: '学情分析',
  //   path: '/MainPage/learningCondition',
  //   status: 1,
  //   children: [{
  //     name: '学科学情',
  //     path: '/MainPage/learningCondition/subjectConditionRouter',
  //     status: 1,
  //   },
  //   {
  //     name: '学生学情',
  //     path: '/MainPage/learningCondition/studentCondition',
  //     status: 1
  //   },
  //   {
  //     name: '学业分析',
  //     path: '/MainPage/learningCondition/schoolWorkCondition',
  //     status: 1
  //   }
  //   ]
  // },
  // {
  //   name: '管理中心',
  //   children: [{
  //     name: '班级管理',
  //     path: '/MainPage/workManage/classManagement',
  //     status: 0
  //   },
  //   {
  //     name: '权限管理',
  //     path: '/MainPage/workManage/authorityManagement',
  //     status: 0
  //   }
  //   ],
  //   path: '/MainPage/workManage',
  // },
];

const routes = [
  {
    name: '首页',
    path: '/MainPage/homePage',
  },
  {
    name: '考试管理',
    path: '/MainPage/exam-manage',
  },
  // {
  //   name: '答题卡中心',
  //   path: '/MainPage/answer-sheet-center',
  // },
  {
    name: '考试阅卷',
    path: '/MainPage/markPaper',
    children: [
      {
        name: '阅卷任务',
        path: '/MainPage/markPaper/markPage',
        children: [
          {
            name: '批改试卷',
            path: '/MainPage/markPaper/markPage/unfinishList',
          },
          {
            name: '试卷仲裁',
            path: '/MainPage/markPaper/markPage/arbitration-home/0',
          },
        ],
      },
      {
        name: '评卷监管',
        path: '/MainPage/markPaper/evaluationRegulationHome',
      },
      // {
      //   name: '批改试卷',
      //   path: '/MainPage/markPage/unfinishList',
      // },
      // {
      //   name: '试卷仲裁',
      //   path: '/MainPage/markPage/arbitration-home/0',
      // },
    ],
  },
  {
    name: '考试报表',
    path: '/MainPage/testReport',
  },
  {
    name: '学情分析',
    path: '/learning-analysis',
  },
  {
    name: '选题组卷',
    path: '/MainPage/paper',
  },
  // {
  //   name: '人员信息',
  //   path: '/MainPage/workManage',
  //   children: [
  //     {
  //       name: '班级管理',
  //       path: '/MainPage/workManage/classManagement',
  //     },
  //     {
  //       name: '权限管理',
  //       path: '/MainPage/workManage/authorityManagement',
  //     },
  //   ],
  // },
];

export { mainPageList, routes };
