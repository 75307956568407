import domain from '@/scripts/domain';
import ContentTypeEnum from '../instance/contentType';
import { Http } from '../instance/instance';
// 由于是沿用此前项目的接口 所以没有测试地址 只有固定地址

// 默认contentType为 multipart/form-data
const request = new Http(domain['examination-single']['exam-api-domain'], ContentTypeEnum.JSON, {
  onResponse(response) {
    if (response.status === 200) return response;
    return Promise.reject(response);
  },
});

export default {
  getScoreInfo: (params) => {
    return request.get('/v1/result/new/search/list2', params);
  },
};
