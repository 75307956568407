export default {
  JLatexServer: { domain: '' },
  'ebag-examination': { 'exam-api-domain': window.location.origin+'/examination-api' },
  'ebag-examination-web': {
    domain: window.location.origin+'/test-paper',
    'dream-ebag-login-domain': '',
    'examination-answer-sheet-domain':window.location.origin+'/answer-sheet',
    'word-guide-paper-web-domain': '',
    'learning-analysis': window.location.origin+'/learning-analysis/#/',
  },
  'accurate-admin-web': {
    webDomain: 'admin',
  },
  'ebag-html2pdf-api': { domain: window.location.origin+'/html2pdf' },
  'examination-answer-sheet-api': {
    QiniuDomain: 'https://afgoals-learning.oss-cn-zhangjiakou.aliyuncs.com/',
    domain: window.location.origin+'/answer-sheet-api',
  },
  zsdata: { domainResource: '' },
  'examination-single': {
    'login-domain': window.location.origin+'/login-admin-api',
    'accurate-admin-web': window.location.origin+'/admin/#/',
    'exam-api-domain': window.location.origin+'/examination-api',
  },
  'three-years-question': { THREE_YEARS_QUESTION: '' },
  "examination-answer-sheet-apiQiniuDomain":"https://afgoals-learning.oss-cn-zhangjiakou.aliyuncs.com/"//阿里云OSS映射域名
};
