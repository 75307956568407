import affairManageRoute from '../affair-manage/routes';
import ScanErrorHandle from '@/modules/scan-error-handle/routes'
export default {
  path: 'exam-manage',
  component: () => import('./ExamManage.vue'),
  children: [
    {
      path: '',
      redirect: 'overview',
    },
    affairManageRoute,
    ScanErrorHandle,
    {
      path: 'overview',
      name: 'overview',
      component: () => import('./exams-overview/ExamsOverview.vue'),
      meta: {
        keepAlive: true,    // 标记该路由组件需要被缓存
        scrollEl: '#exams-overview-main', // 标记该页面中需记录滚动高度的元素
      },
    },
    {
      path: "paper-question",
      name: "paperQuestion",
      component: () => import("./exam-process/views/paperQuestion.vue")
    },
    {
      path: 'create-exam',
      name: 'createExam',
      //   component: () => import('./views/CreateExam.vue'),
      // },
      // {
      //   path: 'create-joint-exam',
      //   name: 'CreateJointExam',
      component: () => import('./create-exam/CreateJointExam.vue'),
    },
    {
      path: 'exam-detail/:examId',
      name: 'exam-detail',
      component: () => import('./views/ExamDetail.vue'),
      props: true,
      children: [
        {
          path: '',
          redirect: 'subject-exam',
        },
        {
          path: 'subject-exam',
          component: () => import('./views/SubjectExam.vue'),
        },
        {
          path: 'staff-manage',
          component: () => import('./views/StaffManage.vue'),
        },
        {
          path: 'result-preliminary',
          component: () => import('./views/ResultPreliminary.vue'),
        },
      ],
    },
    {
      path: 'process',
      name: 'examProcess',
      component: () => import('./exam-process/ExamProcess.vue'),
      children: [
        // {
        //   path: '',
        //   redirect: 'total',
        // },
        {
          path: 'total',
          name: 'totalProcess',
          component: () => import('./exam-process/views/TotalProcess.vue'),
        },
        {
          path: 'subject/:subjectIdStr',
          name: 'subjectProcess',
          component: () => import('./exam-process/views/SubjectView.vue'),
          props: true,
          children: [
            {
              path: '',
              redirect: 'subjectStep',
            },
            {
              path: 'subjectStep',
              name: 'subjectStep',
              // component: () => import('./exam-process/views/SubjectProcess.vue'),
              component: () => import('./exam-process/views/OldSubjectProcess.vue'),
            },
            {
              path: 'alarm',
              name: 'alarm',
              component: () => import('./exam-process/views/AlarmInfo.vue'),
            }
          ]
        },
      ],
    },
    {
      path: 'divideBlock/:examId',
      name: 'divideBlock',
      component: () => import('./views/DivideBlock.vue'),
    },
    // ScanErrorHandle
  ]
};
