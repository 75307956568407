/**
 *  vuex 的用户相关信息模块
 */
import { localStoragePrefix } from "@/scripts/utils";
import Vue from 'vue'
const user = {
  namespaced: true,
  state: () => {
    return {
      account: "",
      info: {},
      roleList: [],
      permission: {},
      answerSheetAliToken: ''
    };
  },
  getters: {
    isSuperAdmin: (state) => state.info.userName === "阅卷管理",
    isScoringTeacher: (state) => (state.info ? parseFloat(state.info.userRole) === 4 : false),
    userId: (state) => state.permission.user_id || "",
    sessionId: (state) => state.info.sessionId || "",
    userName: (state) => state.info.userName || "",
    userSchoolName(state, getters) {
      return state.info.userSchoolName || "";
    },
    userSchoolId(state, getters) {
      return state.info.userSchoolId;
    },
    userSchoolStage(state, getters) {
      return state.info.userStage;
    },
    currentRole: (state) => {
      // 目前仅区分四个角色，1.学校管理员 2.市教研室老师 3.镇教办老师 4.阅卷老师。
      let userRole = parseFloat(state.info.userRole);
      if (userRole === 4) return "scoring_teacher"; // 阅卷老师
      const { level } = state.permission.permission;
      switch (level) {
        case 4:
          return "school_manager"; // 学校管理员
        case 3:
          return "town_edu_teacher"; // 镇教办老师
        case 2:
          return "city_edu_research"; // 市教研院
        case 1:
          return "city_edu_research"; // 省、市教研院
        default:
          return "unknown"; // 其他未定义的角色
      }
    },
    // 联考的业务逻辑，已弃用
    isTeacher: (state, getters) => {
      // return getters.currentRole === "school_manager";
      return true;
    },
    isManage: (state, getters) => {
      return false;
      return ["town_edu_teacher", "city_edu_research"].includes(getters.currentRole);
    },
    permissionIds(state, getters) {
      const { permission: userPermission } = state;
      const {
        permission: { level, province_list, city_list, county_list, school_list },
      } = userPermission;
      switch (level) {
        case 1:
          return { province_ids: JSON.stringify(province_list.map((e) => e.F_id)) };
        case 2:
          return { city_ids: JSON.stringify(city_list.map((e) => e.F_id)) };
        case 3:
          return { county_ids: JSON.stringify(county_list.map((e) => e.F_id)) };
        // 单校版业务逻辑改了，不再传多校id，只传单校学校的 id
        case 4:
          return { school_ids: JSON.stringify([getters.userSchoolId]) };
      }
    },
    permissionSubjectList(state) {
      return state.permission.permission.subject_list;
    },
    permissionSchoolList(state) {
      return state.permission.permission.school_list;
    },
  },
  mutations: {
    SET_ACCOUNT(state, payload) {
      state.account = payload.account;
    },
    SET_USER_INFO(state, payload) {
      state.info = payload.userInfo;
    },
    SET_USER_ROLE_LIST(state, payload) {
      // 有些角色需要放开数据范围的限制
      const { value } = payload;
      value.forEach(item => {
        if(['校务管理员', '校长', '副校长', '教务主任'].includes(item.role)) {
          item.permission.grade_list = [];
          item.permission.class_list = [];
          item.permission.subject_list = [];
        } else if(['年级主任'].includes(item.role)) {
          item.permission.class_list = [];
          item.permission.subject_list = [];
        } else if(['科组长', '备课组长'].includes(item.role)) {
          item.permission.class_list = [];
        }
      })
      state.roleList = payload.value;
    },
    CHANGE_CURR_USER_ROLE(state, payload) {
      state.permission = payload.value;
    },
    SET_USER_SCHOOL(state, payload) {
      const { F_id, F_name } = payload;
      state.info.userSchoolId = F_id;
      state.info.userSchoolName = F_name;
      sessionStorage.setItem(`${localStoragePrefix}user_school_id`, F_id);
      sessionStorage.setItem(`${localStoragePrefix}user_school_name`, F_name);
    },
    SET_ANSWER_SHEET_ALI_TOKEN(state, payload) {
      state.answerSheetAliToken = payload.token;
    },
  },
  actions: {
    async getAliToken({ state, commit }, payload) {
      const now = new Date().getTime() / 1000;
      if (state.answerSheetAliToken && now <= state.answerSheetAliToken.Expiration - 300) return;
      const res = await Vue.prototype.$serve.getStsToken();
      if (res.body.F_responseNo === 10000) {
        commit('SET_ANSWER_SHEET_ALI_TOKEN', { token: res.body.F_data })
      }
    }
  },
};

export default user;
