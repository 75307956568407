/* eslint-disable no-param-reassign */
import { Base64 } from "js-base64";
import { MD5 } from "crypto-js";
import Vue from "vue";
import { mainPageList } from "@/scripts/previlege";
import domain from "./domain";
import { setSubject } from "./analyzeMap.js"
const OSS = require("ali-oss");

const SALT = "c7bbd6f8356b46ad0e3b83b75c74eec7";
const HOST = domain.JLatexServer.domain;
const localStoragePrefix = "examination_";
// 用户登录或者退出清空存储信息时需要保留的字段
const saveKey = [
  `${localStoragePrefix}mode_type`,
  `${localStoragePrefix}self_list`,
  `${localStoragePrefix}version_name`,
  `${localStoragePrefix}user_account`,
  `${localStoragePrefix}selected_list`,
  `${localStoragePrefix}last_info`,
  `${localStoragePrefix}roll_back_record`
];

// 处理公式标签
function formatTex(string) {
  const texRegular = /<tex[\S\s]*?data-latex="([\S\s]*?)">[\S\s]*?<\/tex>/g;
  return string.replace(texRegular, (match, $1) => {
    const head = `<img src="${HOST}tex?`;
    const tex = Base64.encodeURI(
      $1.replace(/&gt;/g, ">").replace(/&lt;/g, "<")
    );
    const token = MD5(SALT + tex);
    const body = `tex=${tex}&token=${token}`;
    const tail = '" style="vertical-align: middle;"">';
    return head + body + tail;
  });
}

// 接受地址栏中的参数
function getRequest() {
  const {
    location: { href }
  } = window;
  if (href.indexOf("?") === -1) {
    return {};
  }
  const index = href.indexOf("?");
  const querys = href.slice(index + 1).split("&");
  return Object.fromEntries(querys.map(s => s.split("=")));
}

function clearIntervalId() {
  clearInterval(sessionStorage.getItem(`${localStoragePrefix}timeout_id`));
}

async function getStsToken() {
  const res = await Vue.prototype.$serve.getStsToken();
  sessionStorage.setItem(
    `${localStoragePrefix}OSSKEY`,
    JSON.stringify(res.data.F_data)
  );
  if (sessionStorage.getItem(`${localStoragePrefix}timeout_id`)) {
    clearIntervalId(sessionStorage.getItem(`${localStoragePrefix}timeout_id`));
  }
  const timeoutId = setTimeout(() => {
    getStsToken();
  }, res.data.F_data.Expiration * 1000 - new Date().getTime());
  sessionStorage.setItem(`${localStoragePrefix}timeout_id`, timeoutId);
}

// 阿里图片资源域名预处理函数
/**
 * @param {String} url 传入的图片资源url
 * @param boolean [enableCompress] 是否启用压缩
 */

// 不采用a标签传递url的原因：
// decode解密后a.href会再次进行加密
function ossFn(url, enableCompress) {
  if (url.indexOf("afgoals-learning.oss-cn-zhangjiakou.aliyuncs.com") !== -1) {
    const { AccessKeyId, AccessKeySecret, SecurityToken } = JSON.parse(
      sessionStorage.getItem(`${localStoragePrefix}OSSKEY`)
    );
    const client = new OSS({
      region: "oss-cn-zhangjiakou",
      accessKeyId: AccessKeyId,
      accessKeySecret: AccessKeySecret,
      stsToken: SecurityToken,
      bucket: "afgoals-learning",
      endpoint: "oss-cn-zhangjiakou.aliyuncs.com"
    });
    const result = enableCompress
      ? client.signatureUrl(decodeURI(url.split(".com")[1]), {
        process: "image/quality,q_50"
      })
      : client.signatureUrl(decodeURI(url.split(".com")[1]));
    return result.replace(/^http+s?/g, "https");
  }
  return url;
}

// 存储会话级的相关数据
function saveSession(info) {
  sessionStorage.setItem(`${localStoragePrefix}session_id`, info.sessionId);
  sessionStorage.setItem(`${localStoragePrefix}user_name`, info.userName);
  sessionStorage.setItem(`${localStoragePrefix}user_id`, info.userId);
}

// 设置页面权限
function setAuthority(dataList, defaultList) {
  defaultList.forEach(defaultItem => {
    dataList.forEach(dataItem => {
      if (defaultItem.name === dataItem.name) {
        defaultItem.status = 1;
        if (dataItem.childs != null) {
          setAuthority(dataItem.childs, defaultItem.children);
        }
      }
    });
  });
}

async function dealRole(_this, info) {
  const userType = sessionStorage[`${localStoragePrefix}type`];
  saveSession(info.F_data);

  const {
    data: { data }
  } = await _this.$serve.getRoleAuthority();
  let pageList = JSON.parse(JSON.stringify(mainPageList));
  if (userType !== 999) {
    pageList[0].children = pageList[0].children.filter(
      e => e.name === "阅卷任务" || e.name === "评卷监管"
    );
  }
  setAuthority(data, pageList);

  sessionStorage.setItem(
    `${localStoragePrefix}main_page_list`,
    JSON.stringify(pageList)
  );
  return Promise.resolve();
}

// 登录初始化流程
async function initLoginInfo(_this, info, userAccount) {
  let modeType;
  let selfList;
  if (sessionStorage[`${localStoragePrefix}self_list`]) {
    selfList = sessionStorage[`${localStoragePrefix}self_list`];
  }
  if (sessionStorage[`${localStoragePrefix}mode_type`]) {
    modeType = JSON.parse(sessionStorage[`${localStoragePrefix}mode_type`]);
  } else {
    modeType = {
      scoreType: 3, // 普通打分：1，点击打分：2,快捷打分：3
      add_type: 0, // 加分方式,普通打分：0，加分打分：1，减分打分：2
      submitType: 2, // 不自动提交：1 ，自动提交：2
      traces: 0 // 点击打分模式的加分模式是否留痕，有痕1，无痕2
    };
  }

  sessionStorage.setItem(
    `${localStoragePrefix}mode_type`,
    JSON.stringify(modeType)
  );
  if (selfList) {
    sessionStorage.setItem(`${localStoragePrefix}self_list`, selfList);
  }
  await getStsToken();
  return dealRole(_this, info, userAccount);
}

function thirdLogin(_this, from, code) {
  const formData = new FormData();
  formData.append("from", from);
  formData.append("code", code);
  return _this.$serve
    .thirdLogin(formData)
    .then(res => {
      _this.$message.success("转跳成功");
      return initLoginInfo(_this, res.body, res.body.F_data.userAccount);
    })
    .catch(err => {
      console.log(err);
      _this.$message.success("第三方登录失败，转至阅卷系统登录页");
      _this.$router.push("/Login");
    });
}

// 下载一个线上文件且格式为 blob 类型
async function downloadFileAsBlob(path) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("get", path);
    xhr.responseType = "blob";
    xhr.send();
    xhr.onload = function () {
      if (this.status === 200 || this.status === 304) {
        return resolve(this.response);
      }
    };
    xhr.onerror = function () {
      return reject(new Error("donwload file error"));
    };
  });
}

function downloadFile(fileUrl, saveName) {
  const xhr = new XMLHttpRequest();
  xhr.open("get", fileUrl);
  xhr.responseType = "blob";
  xhr.send();
  xhr.onload = function () {
    if (this.status === 200 || this.status === 304) {
      const url = URL.createObjectURL(this.response);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = saveName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
}

function treeFind(tree, id) {
  for (const data of tree) {
    if (data.question_id == id) return data;
    if (data.points && data.points.length) {
      const res = treeFind(data.points, id);
      if (res) return res;
    }
  }
  return null;
}

function readTreeNode(tree, propKey, arr) {
  for (let item of tree) {
    arr.push(item);
    if (item[propKey] && item[propKey].length) {
      readTreeNode(item[propKey], propKey, arr);
    }
  }
  return arr;
}

const debounce = (() => {
  let timer = null;
  return (callback, wait) => {
    clearTimeout(timer);
    timer = setTimeout(callback, wait);
  };
})();

function isMobile() {
  const regExp = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  return regExp.test(navigator.userAgent.toLowerCase());
}

// 两个数组取交集, prop1 和 prop2 分别是 arr1 和 arr2 需要对比的属性
function getIntersection(arr1, prop1, arr2, prop2) {
  return arr1.filter(e => arr2.find(u => u[prop2] === e[prop1]));
}

// 不打开 PDF 直接进行下载
function downloadExcel(path, name) {
  const xhr = new XMLHttpRequest();
  xhr.open("get", path);
  xhr.responseType = "blob";
  xhr.send();
  xhr.onload = function () {
    if (this.status === 200 || this.status === 304) {
      // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
      // const url = URL.createObjectURL(blob);
      const url = URL.createObjectURL(this.response);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
}

function getRandomCode() {
  let code = '';
  for (let i = 0; i < 6; i++) {
    code += parseInt(Math.random() * 10);
  }
  return code;
}

function customSort(list, key) {
  list.sort((a, b) =>
    +a[key].replace(/[^0-9]/gi, "") > +b[key].replace(/[^0-9]/gi, "")
      ? 1
      : -1
  );
}

// 获取一个数组，数组长度为 count, 每个元素是取值范围为 [min, max] 的随机数
function getRandomNumArr(min, max, count) {
  const retArr = [];
  while (count) {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    if (!retArr.includes(randomNum)) {
      retArr.push(randomNum)
      count--;
    }
  }
  return retArr;
}

async function customTimeout(delay = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

function getBaseURL() {
  let base = ''
  const deployEnv = process.env.DREAMENV
  const date = new Date()
  const prefix = `${process.env.CI_PROJECT_NAME}/${date.getFullYear()}${date.getMonth() + 1
    }${date.getDate()}`
  switch (deployEnv) {
    case 'TEST':
      base = process.env.TEST_RESOURCE_DOMAIN + prefix
      break
    case 'PROD':
      base = process.env.PROD_RESOURCE_DOMAIN + prefix
      break
    default:
      base = './'
      break
  }
  return base
}

function num2Chinese(number) {
  const map = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    0: ''
  }
  if (number < 10) {
    return map[number]
  } else if (number < 20) {
    return '十' + map[number % 10]
  } else if (number < 100) {
    return map[Math.floor(number / 10)] + '十' + map[number % 10]
  } else {
    return ''
  }
}
const groupBy = (array, f) => {
  let groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};

// 数组对象按照某个属性进行分组
function arrayGroupBy(list, groupId) {
  let sorted = groupBy(list, function (item) {
    return [item[groupId]];
  });
  return sorted;
};

// 根据对象数据某个属性来去重
function deduplicateArrayByProperty(array, property) {
  const uniqueValues = new Set();

  return array.reduce((result, obj) => {
    const value = obj[property];

    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      result.push(obj);
    }

    return result;
  }, []);
}

function chineseToArabic(chineseNum) {
  const numMap = {
    '一': 1,
    '二': 2,
    '三': 3,
    '四': 4,
    '五': 5,
    '六': 6,
    '七': 7,
    '八': 8,
    '九': 9,
    '十': 10
  };

  if (numMap[chineseNum]) return numMap[chineseNum];

  // Special handling for numbers 11-19
  if (chineseNum.startsWith('十')) {
    return 10 + numMap[chineseNum[1]];
  }

  // Special handling for number 20
  if (chineseNum === '二十') return 20;
}


function hasProperty(obj, key){
  return Object.hasOwnProperty.call(obj, key)
}

async function getSubject(){
  const res = await Vue.prototype.$serve.getSubject();
  delete res.body[3000]
  setSubject(res.body)
}
export {
  getRequest,
  formatTex,
  ossFn,
  getStsToken,
  getSubject,
  clearIntervalId,
  localStoragePrefix,
  thirdLogin,
  initLoginInfo,
  saveKey,
  downloadFileAsBlob,
  downloadFile,
  treeFind,
  readTreeNode,
  debounce,
  isMobile,
  getIntersection,
  downloadExcel,
  getRandomCode,
  customSort,
  getRandomNumArr,
  customTimeout,
  getBaseURL,
  num2Chinese,
  arrayGroupBy,
  deduplicateArrayByProperty,
  chineseToArabic,
  hasProperty
};
