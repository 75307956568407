import domain from '@/scripts/domain';
import ContentTypeEnum from '../instance/contentType';
import { Http } from '../instance/instance';

const request = new Http(domain['examination-single']['exam-api-domain'], ContentTypeEnum.JSON, {
  onResponse(response) {
    if (response.status === 200) return response.data;
    return Promise.reject(response.response.data);
  },
});

export default {
  getScoreRes: (params) => {
    const formData = new FormData();
    for (let [k, v] of Object.entries(params)) {
      formData.append(k, v);
    }
    return request.post('/v1/joint/student/score_query/result', formData);
  },
  changePassword: (params) => {
    const formData = new FormData();
    for (let [k, v] of Object.entries(params)) {
      formData.append(k, v);
    }
    return request.put('/v1/joint/student/score_query/reset_pwd', formData);
  },
};
