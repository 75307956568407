<template>
  <div>
    <div v-show="visible&&type===''" class="msg">
      <i class="close" @click="visible=false"></i>
      <h1>{{title}}</h1>
      <p class="text">{{text}}</p>

      <div class="button">
        <!-- <button class="cancel" @click="visible=flase">取消</button> -->
        <button class="determine" @click="visible=false">确定</button>
      </div>
    </div>

    <div v-show="visible&&type==='excelTip'" class="msg">
      <i class="close" @click="visible=false"></i>
      <h1>{{title}}</h1>
      <p class="text">{{text}}</p>
      <span class="blue" @click="detailShow=true">查看详情</span>
      <div class="detail-contain" v-show="detailShow">
        <p v-for="(item,index) in sameIdStudentInfos" :key="index">
          学号
          <span class="red-text">{{item.number}}</span>已被
          <span class="red-text">{{item.name}}</span>使用
        </p>
      </div>
      <div class="button">
        <!-- <button class="cancel" @click="visible=flase">取消</button> -->
        <button class="determine" @click="visible=false">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      text: "",
      type: "",
      visible: false,
      detailShow: false,
      sameIdStudentInfos: []
    };
  }
};
</script>

<style lang="less" scoped>
.msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 591px;
  height: auto;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(105, 105, 105, 0.5);
  border-radius: 12px;
  z-index: 123;

  .close {
    position: absolute;
    display: block;
    width: 21px;
    height: 21px;
    right: 12px;
    top: 12px;
    background: url("@/assets/images/homepage/close.png") no-repeat;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: rotate(180deg);
    }
  }

  img {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }

  h1 {
    margin-top: 40px;
    color: #262626;
    font-size: 16px;
  }

  .text {
    display: inline-block;
    margin: 54px auto;
  }
  .blue {
    color: #1ca7ff;
    text-decoration: underline;
    cursor: pointer;
  }
  .detail-contain {
    margin: 0 5px;
    margin-bottom: 40px;
    overflow: auto;
    max-height: 150px;

    .red-text {
      display: inline-block;
      width: 130px;
      color: #e84c3d;
    }
  }

  .button {
    margin-bottom: 25px;
    button {
      margin: 0 50px;
      height: 40px;
      width: 97px;
      border-radius: 2px;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #1ca7ff;
      border: 1px solid #1ca7ff;
      transition: 0.3s;

      &:hover {
        color: #1ca7ff;
        background-color: #ecf5ff;
        border: 1px solid #1ca7ff;
      }
    }
    .determine {
      background-color: #1ca7ff;
      color: #fff;
      border: none;
      transition: 0.3s;

      &:hover {
        background-color: #3fb5ff;
      }
    }
  }
}
.detail-contain {
  &::-webkit-scrollbar {
    display: block;
  }
}
</style>
