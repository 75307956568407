export default {
  path: 'workManage',
  name: 'workManage',
  component: () => import('./WorkManage.vue'),
  // children: [
  //   {
  //     path: 'classManagement',
  //     name: 'classManagement',
  //     component: () => import('./class-manage/ClassManagement.vue'),
  //   },
  //   {
  //     path: 'authorityManagement',
  //     name: 'authorityManagement',
  //     component: () => import('./authority-manage/AuthorityManagement.vue'),
  //   },
  // ],
};
