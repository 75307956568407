/**
 *  vuex 的用户相关信息模块
 */
const schoolConfig = {
  namespaced: true,
  state: () => {
    return {
      authorityConfig: null,
    };
  },
  getters: {
    // 有权限配置代表有限制
    isLimit(state) {
      //return state.authorityConfig !== null;
      return false
    },
  },
  mutations: {},
  actions: {
    async SET_AUTHORITY_CONFIG({ state }, payload) {
      state.authorityConfig = payload.value;
    },
  },
};

export default schoolConfig;
