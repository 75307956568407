export default {
  bind(el, binding) {
    // 获取element UI定义好的scroll元素
    const SELECTWRAP_ROM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    if (SELECTWRAP_ROM) {
      // 添加scroll事件
      SELECTWRAP_ROM.addEventListener('scroll', function () {
        // 判断滚动
        const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
        condition && binding.value();
      });
    }
  },
};
