/***
 *  《考试阅卷》模块下面的路由表
 */
export default {
  // nowTool当前阅卷流程所在的组件名 
  // filterChoices只显示选择题异常 
  // hideHandleBlock隐藏右侧的处理方式  
  // choiceCoordError只显示客观题框选异常
  path: 'scan-error-handle/:subject/:nowTool/:filterChoices/:hideHandleBlock/:choiceCoordError',
  name: "scan-error-handle",
  component: () => import('./correct.vue'),
  props: true
};
