const scanErrorHandle = {
    namespaced: true,
    state: {
        scoreErrorTabVisible: false,
        optionErrorTabVisible: false,
        paperCoordInfo: {},
        examInfo: {},
        deleteFlag: 0,//异常处理删除学生时 查看全部面板已经显示 这时候要更新查看面板里的数据 popover组件watch这个值的变化进行更新
        coordInfoObj: {}, // 当前考试 框选的的坐标信息 
        exampleCoord: {}, // 查看框选的模板
        hideHandle: false // 从成绩报表 点击选择题进来的时候 隐藏四种处理方式 只显示 修改选择题答案
    },
    mutations: {
        UPDATE_CORRD_INFO(state, { key, data }) {
            state.coordInfoObj[key] = data
        },
        UPDATE_SCORE_ERROR_TAB_VISIBLE(state, boolean) {
            state.scoreErrorTabVisible = boolean;
        },
        UPDATE_OPTION_ERROR_TAB_VISIBLE(state, param) {
            state.optionErrorTabVisible = param;
        },
        // 已经框选后 查看全部切换试卷时 保存已经框选的坐标信息
        SAVE_COORD_INFO(state, data) {
            state.paperCoordInfo[data.id] = data.coordArr;
        },
        DELETE_COORD_INFO(state, id) {
            if (state.paperCoordInfo[id]) {
                delete state.paperCoordInfo[id];
            }
        },
        CHANGE_EXAM_INFO(state, info) {
            window.sessionStorage.setItem("CURRENT_EXAM_INFO", JSON.stringify(info));
            state.examInfo = info;
        },
        UPDATE_DELETE_COUNT(state) {
            state.deleteFlag++
        },
        UPDATE_EXAMPLE_COORD(state, data) {
            state.exampleCoord = data
        },
        UPDATE_HIDE_HANDLE(state, boolean) {
            state.hideHandle = boolean
        }

    },
}

export default scanErrorHandle