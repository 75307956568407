import axios from 'axios';
/**
 * @description 管理取消请求
 */
class AxiosCanceler {
  constructor() {
    this.pendingMap = new Map();
  }
  generateKey(config) {
    const { method, url, params, data } = config;
    let r = [method, url, JSON.stringify(params), JSON.stringify(data)].join('&');
    return r;
  }
  /**
   * @description: 添加请求
   * @param {Object} config
   * @return void
   */
  add(config) {
    // * 在请求开始前，对之前的请求做检查取消操作
    this.remove(config);
    let requestKey = this.generateKey(config);
    if (config.cancelTokenKey) {
      requestKey = config.cancelTokenKey;
    }
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!this.pendingMap.has(requestKey)) {
          this.pendingMap.set(requestKey, cancel);
        }
      });
  }

  get(key) {
    return this.pendingMap.get(key);
  }
  /**
   * @description: 移除请求
   * @param {Object} config
   */
  remove(config) {
    const requestKey = this.generateKey(config);
    if (this.pendingMap.has(requestKey)) {
      const cancelToken = this.pendingMap.get(requestKey);
      cancelToken && cancelToken(requestKey);
      this.pendingMap.delete(requestKey);
    }
  }
  /**
   * @description: 清空所有pending
   */
  clear() {
    for (const [requestKey, cancelToken] of this.pendingMap) {
      cancelToken(requestKey);
    }
    this.pendingMap.clear();
  }
}

const axiosCanceler = new AxiosCanceler();

export default axiosCanceler;
