import axios from 'axios';
import { isPromise } from '@/scripts/validate';
import axiosCanceler from './axiosCancel';
import qs from 'qs';
import ContentTypeEnum from './contentType';

const getConfig = (baseConfig = {}, contentType) => {
  if (!contentType) return baseConfig || {};
  let headers = {};
  if (baseConfig.headers) {
    headers = { ...baseConfig.headers };
  }
  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  return {
    ...baseConfig,
    headers,
  };
};

const requestInterceptors = (config) => {
  axiosCanceler.remove(config);
  axiosCanceler.add(config);

  if (config.headers && config.headers['Content-Type'] === ContentTypeEnum.FORM_URLENCODED) {
    if (config.data) {
      config.data = qs.stringify(config.data);
    }
  }
  return config;
};

export class Http {
  //  instance;

  constructor(baseURL, baseContentType, { onRequest, onResponse }) {
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': baseContentType,
      },
    });
    this.instance.interceptors.request.use(
      (config) => {
        if (onRequest) {
          const returnVal = onRequest(config);
          if (isPromise(returnVal)) {
            return returnVal.then((conf) => {
              return requestInterceptors(conf);
            });
          }
          return requestInterceptors(returnVal);
        }
        return requestInterceptors(config);
      },
      (error) => {
        axiosCanceler.clear();

        return Promise.reject(error);
      }
    );
    this.instance.interceptors.response.use(
      (response) => {
        axiosCanceler.remove(response.config);
        return onResponse ? onResponse(response) : response;
      },
      (error) => {
        axiosCanceler.remove(error.config || {});
        if (axios.isCancel(error)) {
          // 是取消请求的错误
          console.warn(error);
          return Promise.reject({ ...error, isCancel: true });
        } else {
          return onResponse ? onResponse(error) : Promise.reject(error);
        }
      }
    );
  }
  get(url, query, config) {
    return this.instance.request({
      ...config,
      url,
      params: query,
      method: 'get',
    });
  }
  post(url, data, contentType, config) {
    return this.instance.request({
      ...getConfig(config, contentType),
      url,
      data,
      method: 'post',
    });
  }
  patch(url, data, contentType, config) {
    return this.instance.request({
      ...getConfig(config, contentType),
      url,
      data,
      method: 'patch',
    });
  }
  put(url, data, contentType, config) {
    return this.instance.request({
      ...getConfig(config, contentType),
      url,
      data,
      method: 'put',
    });
  }
  delete(url, query, contentType, config) {
    return this.instance.request({
      ...getConfig(config, contentType),
      url: url,
      params: query,
      method: 'delete',
    });
  }
}
