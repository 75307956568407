<template>
  <div class="rb-dialog" :class="{ shadow }" v-if="show">
    <div
      class="dialog-block"
      :class="[blockClass]"
      :style="{ width: width, height: height }"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      <div class="dialog-header">
        <div class="header-title">
          {{ title }}
        </div>
        <img
          draggable="false"
          src="@/assets/images/common/dialog-close.png"
          alt=""
          class="header-close"
          @click="
            $emit('update:show', false);
            $emit('close');
          "
        />
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
      <div class="dialog-body-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    shadow: Boolean,
    width: {
      type: String,
      default: "50%",
    },
    height: {
      type: String,
      default: "70%",
    },
    blockClass: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "加载中，请稍候",
    },
  },
};
</script>

<style lang="less" scoped>
.rb-dialog {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &.shadow {
    background-color: rgba(38, 38, 38, 0.4);
  }
  .dialog-block {
    background-color: #fff;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // 固定高度（即不随着窗体大小变换位置）
    &.fixed-top {
      top: 15%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .dialog-header {
    margin: 0;
    width: 100%;
    height: 60px;
    border-radius: 12px 12px 0 0;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #262626;
  }
  .header-close {
    cursor: pointer;
    position: absolute;
    right: 18px;
    width: 20px;
    // transition: .5s;
    // &:hover {
    //   transform: rotate(360deg);
    // }
  }
  .dialog-body {
    box-sizing: border-box;
    &.min-height {
      min-height: 360px;
    }
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(50, 50, 50, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(50, 50, 50, 0.3);
      &:hover {
        background-color: #717171;
      }
    }
  }
  .dialog-body-header,
  .dialog-body-footer {
    margin: 0;
    width: 100%;
  }
}
</style>
