<template>
  <div id="app">
    <!-- <keep-alive include="main-page-wrapper" v-if="isLoginIn"> -->
    <!--   <router-view></router-view>                             -->
    <!-- </keep-alive>                                             -->
    <!-- <router-view v-if="!isLoginIn"></router-view>             -->
    <!--
      请注意这里对 MainPage 组件的缓存作用
    -->
    <keep-alive :include="['MainPage']">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { getStsToken,getSubject } from "@/scripts/utils";
import {
  localStoragePrefix,
} from "@/scripts/utils";
export default {
  name: "App",
  data() {
    return {
      isLoginIn: false
    }
  },
  watch: {
    $route(to, from) {
      // 退出登录的时候 移除keep-alive
      let userId = sessionStorage.getItem(`${localStoragePrefix}user_id`)
      if (userId) {
        this.isLoginIn = true
      } else {
        this.isLoginIn = false
      }
    }
  },
  mounted() {
    getStsToken();
    getSubject();
  }
};
</script>

<style lang='less'>
@import "@/assets/style/index.less";

#app {
  width: 100vw;
  height: 100vh;
}
</style>
